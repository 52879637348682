<template>
  <HXContentCard title="Gestión cuenta de cobros" :defaultToBack="true">
    <!-- modal agregar fechas -->
    <b-modal id="agregarFecha" title="Habilitar fecha" size="md" hide-footer>
      <p v-if="!ultimaFecha">
        No existen fechas habilitadas para la auditoría de cuenta de cobros. Por
        favor, habilita una nueva fecha para la auditoría.
      </p>
      <p>
        <span class="text-danger">*</span> Estas configuraciones se deben
        realizar en los primeros 10 días de cada mes.
      </p>

      <p class="text-right" v-if="ultimaFecha">
        <i class="fas fa-circle-check"></i>
        Ultima actualización: <br />
        <strong
          v-b-tooltip
          :title="
            $moment(ultimaFecha?.createdAt || ultimaFecha?.CreatedAt).format(
              'DD MMM, YYYY hh:mm a'
            )
          "
          >{{
            ultimaFecha?.createdAt ||
            ultimaFecha?.CreatedAt | moment("from", "now")
          }}</strong
        >
      </p>
      <form class="row p-2" @submit.prevent="saveNewDate">
        <div class="col-12 mb-3">
          <label for="date" class="form-label">Mes y Año</label>
          <input
            id="date"
            type="month"
            class="form-control"
            v-model="currentDate"
            @input="handleDateInput"
            :min="minMonthYear"
            :max="maxMonthYear"
            required
          />
        </div>
        <div class="col-12 mb-3">
          <label for="fechaInico" class="form-label">Fecha inicio</label>
          <input
            id="fechaInico"
            type="date"
            class="form-control"
            v-model="nuevaFecha.fechaInicio"
            :min="minFecha"
            :max="maxFecha"
            required
          />
        </div>
        <div class="col-12 mb-3">
          <label for="fechaFin" class="form-label">Fecha fin</label>
          <input
            id="fechaFin"
            type="date"
            class="form-control"
            v-model="nuevaFecha.fechaFin"
            :min="minFecha"
            :max="maxFecha"
            required
          />
        </div>

        <vs-button class="ml-auto" type="submit" v-if="isNewDateDifferent">
          Guardar
        </vs-button>
      </form>
    </b-modal>
    <div class="text-right px-4 mt-2">
      <div class="d-flex justify-content-end">
        <vs-button icon success class="mx-1 text-right" v-b-modal.agregarFecha>
          <i class="fas fa-calendar-check mr-1"></i>
          Habilitar fecha
        </vs-button>
      </div>
    </div>
    <div class="table-responsive p-4">
      <table
        id="datatable_profesionales"
        class="table table-bordered table-responsive-md table-striped text-center"
      >
        <thead>
          <tr>
            <th>#</th>
            <th>Numero documento</th>
            <th>Nombre del profesional</th>
            <!-- <th>Estado</th> -->
            <th>Opciones</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in listProfesionales" :key="item.id">
            <td>
              <strong>
                {{ index + 1 }}
              </strong>
            </td>
            <td>{{ item.NumeroDocumento }}</td>
            <td>{{ item.Nombres }} {{ item.Apellidos }}</td>
            <!-- <td
              class="mt-3"
              :class="
                item.Estado == 1 ? 'badge badge-success' : 'badge badge-danger'
              "
            >
              {{ item.Estado == 1 ? "Activo" : "Inactivo" }}
            </td> -->
            <td style="width: 150px !important">
              <vs-button
                icon
                class="mx-auto"
                v-b-tooltip.hover
                title="Ver soportes"
                :to="{
                  name: 'ListaMesProfesionalCuentaCobro',
                  params: {
                    personaId: item.Id,
                    profesional: `${item.Nombres} ${item.Apellidos}`,
                  },
                }"
              >
                <i class="fas fa-file-lines"></i>
              </vs-button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </HXContentCard>
</template>
<script>
import Swal from "sweetalert2";
import { core } from "../../../config/pluginInit";

export default {
  data() {
    return {
      listProfesionales: [],
      currentDate: null,
      ultimaFecha: null,
      nuevaFecha: {
        anio: null,
        mes: null,
        fechaInicio: null,
        fechaFin: null,
        empresaId: null,
        createdAt: this.$moment
          .tz(new Date(), "America/Bogota")
          .format("YYYY-MM-DDTHH:mm:ss"),
      },
      minFecha: "", // Primer día del mes actual
      maxFecha: "", // Último día del mes actual
      minMonthYear: "", // Mes y año mínimo (mes actual)
      maxMonthYear: "", // Mes y año máximo (mes actual)
    };
  },
  async mounted() {
    this.$isLoading(true);
    this.personaAct = JSON.parse(localStorage.getItem("setPersonaAct"));
    let empresaId = this.personaAct.empresa.id;

    console.log(empresaId);
    // Calcular las fechas mínimas y máximas
    this.calculateMinMaxDate();

    await this.getUltimaFecha(empresaId);
    await this.getProfesionales(empresaId);

    core.index();
    window.$("#datatable_profesionales").DataTable({
      language: {
        url: "//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json",
      },
    });
    this.$isLoading(false);
  },
  computed: {
    isNewDateDifferent() {
      // Compara el año y mes actuales con la última fecha guardada
      if (!this.ultimaFecha) return true; // Si no hay última fecha, siempre muestra el botón
      const currentYearMonth = `${new Date().getFullYear()}-${String(
        new Date().getMonth() + 1
      ).padStart(2, "0")}`;
      const ultimaYearMonth = `${
        this.ultimaFecha?.Anio || this.ultimaFecha?.anio
      }-${String(this.ultimaFecha?.Mes || this.ultimaFecha?.mes).padStart(
        2,
        "0"
      )}`;
      return currentYearMonth !== ultimaYearMonth;
    },
  },

  methods: {
    calculateMinMaxDate() {
      const now = new Date();

      // Primer día del mes actual
      const firstDayOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
      this.minFecha = firstDayOfMonth.toISOString().split("T")[0];

      // Último día del mes actual
      const lastDayOfMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0);
      this.maxFecha = lastDayOfMonth.toISOString().split("T")[0];

      // Formato de mes y año para el campo de mes y año
      this.minMonthYear = `${now.getFullYear()}-${String(
        now.getMonth() + 1
      ).padStart(2, "0")}`;
      this.maxMonthYear = this.minMonthYear; // El mes máximo es igual al mes actual
    },

    async saveNewDate() {
      try {
        this.$isLoading(true);

        this.nuevaFecha.empresaId = this.personaAct.empresa.id;

        const res = await this.$store.dispatch("hl_post", {
          path: "CDC/AddFecha",
          data: this.nuevaFecha,
        });

        console.log(res);
        if (res.id) {
          this.ultimaFecha = this.nuevaFecha;
          Swal.fire("Listo!", "Nueva fecha habilitada con éxito.", "success");
        }
        this.$isLoading(false);
      } catch (error) {
        this.$isLoading(false);
        console.error("Error al guardar cambios:", error);
        Swal.fire("Error!", "No se pudieron guardar los cambios.", "error");
      }
    },
    handleDateInput(event) {
      const [anio, mes] = event.target.value.split("-");
      this.nuevaFecha.anio = anio;
      this.nuevaFecha.mes = mes;
      console.log(this.nuevaFecha);
    },
    async getUltimaFecha(empresaId) {
      const query = `
      SELECT TOP 1 *
      FROM CDCFechas cdc 
      WHERE cdc.EmpresaId = @empresaId
      ORDER BY cdc.CreatedAt DESC;
        `;
      try {
        const response = await this.$store.getters.fetchPost({
          path: `DynamicQuery/`,
          data: {
            query,
            parameters: {
              empresaId,
            },
          },
        });
        if (response.ok) {
          const allData = await response.json();
          console.log(allData.data);
          if (allData.data.length > 0) {
            this.ultimaFecha = allData.data[0];

            this.currentDate = `${this.ultimaFecha.Anio}-${String(
              this.ultimaFecha.Mes
            ).padStart(2, "0")}`;
            this.nuevaFecha.fechaInicio = new Date(this.ultimaFecha.FechaInicio)
              .toISOString()
              .split("T")[0];
            this.nuevaFecha.fechaFin = new Date(this.ultimaFecha.FechaFin)
              .toISOString()
              .split("T")[0];
          }
        } else {
          console.error("Error en la respuesta del servidor", response);
        }
      } catch (error) {
        console.error("Error en getProfesionales:", error);
      }
    },
    async getProfesionales(empresaId) {
      const query = `
        SELECT p.Id,p.NumeroDocumento, p.Nombres,p.Apellidos, p.Estado
        FROM Persona p
        WHERE p.EmpresaId = @empresaId AND p.Estado = 1`;
      try {
        const response = await this.$store.getters.fetchPost({
          path: `DynamicQuery/`,
          data: {
            query,
            parameters: {
              empresaId,
            },
          },
        });
        if (response.ok) {
          const allData = await response.json();
          console.log(allData.data);

          this.listProfesionales = allData.data;
        } else {
          console.error("Error en la respuesta del servidor", response);
          this.listProfesionales = [];
        }
      } catch (error) {
        console.error("Error en getProfesionales:", error);
        this.listProfesionales = [];
      }
    },
  },
};
</script>
<style></style>
